import type { Locale } from '$lib/validators'
import type { TranslationKeys } from './translation-keys'

const translations: Record<Locale, Record<TranslationKeys, string>> = {
  'en-US': {
    locale: 'en-US',
    time_postfix: '',
    save: 'Save',
    status: 'Status',
    email_campaign: 'Campaign',
    open_rate: 'Open Rate',
    click_rate: 'Click Rate',
    revenue: 'Revenue',
    draft: 'Draft',
    scheduled: 'Scheduled',
    sending: 'Sending',
    sent: 'Sent',
    failed: 'Failed',
    edited: 'Edited',
    create_email: 'Create Email',
    email_consultation: 'Email Consultation',
    overview: 'Overview',
    bounce_rate: 'Bounce Rate',
    spam_rate: 'Spam Rate',
    unsubscribed: 'Unsubscribed',
    subject: 'Subject',
    target_group: 'Audience',
    send_time: 'Send Time',
    recipients: 'recipients',
    click_behavior: 'Click Behavior',
    no_emails_yet: 'Your created emails will be displayed here!',
    start_now: 'Start Now',
    template_settings: 'Templates',
    shop_settings: 'Shop Settings',
    admin: 'Admin',
    logout: 'Log Out',
    create_template: 'Create Template',
    confirm_stop_scheduled_email: 'Are you sure you want to cancel the sending of the email "{name}"?',
    confirm_delete_email: 'Are you sure you want to delete the email "{name}"?\n\nThis action cannot be undone.',
    preview: 'Preview',
    content: 'Content',
    style: 'Style',
    settings: 'Settings',
    import_to_klaviyo: 'Import to Klaviyo',
    update_in_klaviyo: 'Update in Klaviyo',
    send_with_ai_segmentation: 'Send with AI Segmentation',
    preview_device_is_generating: 'Device preview is being generated. This may take up to a minute.',
    preview_text: 'Preview Text',
    font: 'Font',
    font_size: 'Font Size',
    font_color: 'Font Color',
    text_align: 'Text Alignment',
    letter_spacing: 'Letter Spacing',
    hr: 'Horizontal Line',
    color: 'Color',
    height: 'Height',
    padding: 'Padding',
    product: 'Product',
    max_image_width: 'Max Image Width',
    text_color: 'Text Color',
    show_price: 'Show Price',
    show_compare_at_price: 'Show Compare Price',
    button_text: 'Button Text',
    button_color: 'Button Color',
    button_font_size: 'Button Font Size',
    button_font_style: 'Button Font Style',
    button_background_color: 'Button Background Color',
    button_padding: 'Button Padding',
    background_color: 'Background Color',
    background_image: 'Background Image',
    margin: 'Margin',
    layout: 'Layout',
    mobile_behavior: 'Mobile Behavior',
    block_html: 'HTML',
    block_spacer: 'Spacer',
    block_hr: 'Divider',
    block_product: 'Product',
    block_2_columns: '2 Cols',
    block_3_columns: '3 Cols',
    block_4_columns: '4 Cols',
    block_section: 'Section',
    block_heading_1: 'H1',
    block_heading_2: 'H2',
    block_text: 'Text',
    block_button: 'Button',
    block_image: 'Image',
    column_layout: 'Column Layout',
    column_mobile_behavior: 'Column Mobile Behavior',
    text: 'Text',
    font_family: 'Font Family',
    link_color: 'Link Color',
    line_height: 'Line Height',
    url: 'URL',
    align: 'Alignment',
    border: 'Border',
    border_radius: 'Border Radius',
    border_color: 'Border Color',
    image_upload: 'Image Upload',
    alt_text: 'Alt Text',
    width: 'Width',
    html: 'HTML',
    stockfoto: 'Stock Photo',
    stockfoto_with_text: 'Stock Photo with Text',
    illustration: 'Digital Illustration',
    image_with_caption: 'Image with Caption (free style)',
    image_upload_file_input_help: 'PNG, JPG, or GIF (images will be automatically optimized).',
    image_upload_generate_image: 'Generate Image',
    image_upload_generate_image_button: 'Generate Image',
    image_upload_generate_image_prompt_placeholder: 'Prompt',
    schedule_email_title: 'When should the email be sent?',
    schedule_email_time_placeholder: 'Select time',
    schedule_email_select_time_button: 'Select Send Time',
    schedule_email_schedule_button: 'Schedule Email',
    schedule_email_timezone_info: 'Timezone: {timezone}',
    done: 'Done',
    new_chat: 'New Chat',
    hello_name: 'Hello {name},',
    how_can_i_help: 'How can I help?',
    create_editorial_plan: 'Create an editorial plan for next week.',
    find_best_send_time: 'Help me find the best send time.',
    account_audit: 'Conduct an account audit.',
    ask_question: 'Enter question...',
    email_variant_create_kind_title: 'Email Type',
    email_variant_create_kind_select_title: 'Please choose:',
    email_variant_kind_content: 'Content Email',
    email_variant_kind_promotion: 'Promotion',
    email_variant_kind_launch: 'Product Launch',
    email_variant_kind_from_website: 'Email from Website',
    email_variant_create_next_button: 'Next',
    email_variant_create_topic_title: 'Email Topic',
    email_variant_create_topic_placeholder: 'Describe the topic in simple words',
    email_variant_create_topic_example: 'Example: New collection arrives this week or Top 3 tips for summer.',
    email_variant_create_discount_code_title: 'Describe the promotion',
    email_variant_create_discount_code_placeholder: 'Describe the promotion in simple words, including legal aspects and limitations...',
    email_variant_create_discount_code_optional: 'Optional:',
    email_variant_create_discount_code_placeholder_discount_code: 'Enter the discount code here...',
    email_variant_create_error_title: 'Unfortunately, an error occurred!',
    email_variant_create_has_promotion_title: 'Should the email include a promotion or discount code?',
    email_variant_create_has_promotion_yes: 'Yes',
    email_variant_create_has_promotion_no: 'No',
    email_variant_create_images_title: 'Image Upload',
    email_variant_create_images_upload_error: 'Unfortunately, an error occurred!',
    email_variant_create_images_upload_title: 'Upload Images',
    email_variant_create_images_upload_help: 'PNG, JPG, or GIF. Images will be automatically compressed and optimized.',
    email_variant_create_images_upload_button: 'Upload Images',
    email_variant_create_images_add_ai_images_button: 'Add AI Images',
    email_variant_create_images_generate_ai_images_button: 'Generate AI Images',
    email_variant_create_link_website_title: 'Which website should serve as a template?',
    email_variant_create_link_promotion_title: 'Which link should be promoted?',
    email_variant_create_start_generating_button: 'Generate Newsletter',
    email_variant_create_notes_title: 'What other information is important?',
    email_variant_create_notes_placeholder: 'Please add any other relevant background information in simple words...',
    email_variant_create_products_title: 'Select Products:',
    email_variant_create_products_error: 'Error loading products: {error}',
    email_variant_create_products_select_title: 'Select product(s):',
    email_variant_create_products_not_found: 'Not found.',
    email_variant_create_target_title: 'What should be promoted?',
    email_variant_create_target_shop_button: 'The Shop',
    email_variant_create_target_products_button: 'Specific Products',
    email_variant_create_target_link_button: 'A Link',
    email_variant_editor_placeholder_text:
      'This text is a placeholder and will be replaced by AI-generated content. <strong>These words are particularly important</strong> and have been highlighted in bold. Sometimes we need <em>subtle nuances</em> that are represented by italic text. It is also useful to underline certain elements <u>to emphasize their importance further</u>. For more information, please visit our <a href="https://example.com">website</a>.',
    email_variant_editor_placeholder_button: 'Button',
    email_variant_editor_placeholder_product_title: 'Product Name',
    email_variant_editor_placeholder_product_description: 'Description',
    shop_settings_title: 'Settings for {shopName}',
    shop_settings_language: 'Email Language',
    shop_settings_custom_instructions: 'Custom Instructions',
    shop_settings_custom_instructions_placeholder: 'e.g. start every email with an image.',
    shop_settings_currency: 'Currency',
    shop_settings_timezone: 'Timezone',
    email_variant_create_error_try_again_button: 'Try again',
    editor_settings_email_width: 'Width',
    editor_settings_email_base_font_family: 'Base Font Family',
    editor_settings_email_background_color: 'Background Color',
    editor_settings_email_frame_color: 'Frame Color',
    feedback_widget_trigger_label: 'Report a Bug',
    feedback_widget_trigger_aria_label: 'Report a Bug',
    feedback_widget_form_title: 'Report a Bug',
    feedback_widget_submit_button_label: 'Send Bug Report',
    feedback_widget_cancel_button_label: 'Cancel',
    feedback_widget_confirm_button_label: 'Confirm',
    feedback_widget_add_screenshot_button_label: 'Add a screenshot',
    feedback_widget_remove_screenshot_button_label: 'Remove screenshot',
    feedback_widget_name_label: 'Name',
    feedback_widget_name_placeholder: 'Your Name',
    feedback_widget_email_label: 'Email',
    feedback_widget_email_placeholder: 'your.email@example.org',
    feedback_widget_is_required_label: '(required)',
    feedback_widget_message_label: 'Description',
    feedback_widget_message_placeholder: "What's the bug? What did you expect?",
    feedback_widget_success_message_text: 'Thank you for your report!',
    flow_name: 'Name',
    flows_no_flows_yet: 'Your flows will be displayed here!',
    flow_confirm_delete: 'Are you sure you want to delete the flow "{name}"?\n\nThis action cannot be undone.',
    flow_name_prompt: 'How should the flow be named?',
  },
  'de-DE': {
    locale: 'de-DE',
    time_postfix: 'Uhr',
    save: 'Speichern',
    status: 'Status',
    email_campaign: 'Kampagne',
    open_rate: 'Öffnungsrate',
    click_rate: 'Klickrate',
    revenue: 'Umsatz',
    draft: 'Entwurf',
    scheduled: 'Geplant',
    sending: 'Senden',
    sent: 'Gesendet',
    failed: 'Fehlgeschlagen',
    edited: 'Bearbeitet',
    create_email: 'Neue E-Mail erstellen',
    email_consultation: 'E-Mail Beratung',
    overview: 'Übersicht',
    bounce_rate: 'Bounce Rate',
    spam_rate: 'Spam Rate',
    unsubscribed: 'Abgemeldet',
    subject: 'Betreff',
    target_group: 'Zielgruppe',
    send_time: 'Sendezeit',
    recipients: 'Empfänger',
    click_behavior: 'Klick Verhalten',
    no_emails_yet: 'Hier werden deine erstellten E-Mails angezeigt!',
    start_now: 'Jetzt loslegen',
    template_settings: 'Vorlagen',
    shop_settings: 'Shop Einstellungen',
    admin: 'Admin',
    logout: 'Ausloggen',
    create_template: 'Template erstellen',
    confirm_stop_scheduled_email: 'Bist du sicher, dass du das Senden der E-Mail "{name}" abbrechen möchtest?',
    confirm_delete_email: 'Bist du sicher, dass du die E-Mail "{name}" löschen möchtest?\n\nDiese Aktion kann nicht rückgängig gemacht werden.',
    preview: 'Vorschau',
    content: 'Inhalt',
    style: 'Style',
    settings: 'Einstellungen',
    import_to_klaviyo: 'In Klaviyo importieren',
    update_in_klaviyo: 'In Klaviyo aktualisieren',
    send_with_ai_segmentation: 'Mit AI-Segmentierung versenden',
    preview_device_is_generating: 'Vorschau auf Gerät wird generiert. Dies kann bis zu einer Minute dauern.',
    preview_text: 'Vorschau Text',
    font: 'Schrift',
    font_size: 'Schriftgröße',
    font_color: 'Schriftfarbe',
    text_align: 'Textausrichtung',
    letter_spacing: 'Zeichenabstand',
    hr: 'Horizontale Linie',
    color: 'Farbe',
    height: 'Höhe',
    padding: 'Innenabstand',
    product: 'Produkt',
    max_image_width: 'Maximale Bildbreite',
    text_color: 'Textfarbe',
    show_price: 'Preis anzeigen',
    show_compare_at_price: 'Vergleichspreis anzeigen',
    button_text: 'Button Text',
    button_color: 'Button Farbe',
    button_font_size: 'Button Schriftgröße',
    button_font_style: 'Button Schriftart',
    button_background_color: 'Button Hintergrundfarbe',
    button_padding: 'Button Innenabstand',
    background_color: 'Hintergrundfarbe',
    background_image: 'Hintergrundbild',
    margin: 'Außenabstand',
    layout: 'Layout',
    mobile_behavior: 'Mobile Verhalten',
    block_html: 'HTML',
    block_spacer: 'Leerzeichen',
    block_hr: 'Horizontale Linie',
    block_product: 'Produkt',
    block_2_columns: '2 Spalten',
    block_3_columns: '3 Spalten',
    block_4_columns: '4 Spalten',
    block_section: 'Abschnitt',
    block_heading_1: 'Überschrift 1',
    block_heading_2: 'Überschrift 2',
    block_text: 'Text',
    block_button: 'Button',
    block_image: 'Bild',
    column_layout: 'Spaltenlayout',
    column_mobile_behavior: 'Spaltenmobiles Verhalten',
    text: 'Text',
    font_family: 'Schriftart',
    link_color: 'Linkfarbe',
    line_height: 'Zeilenabstand',
    url: 'URL',
    align: 'Ausrichtung',
    border: 'Rahmen',
    border_radius: 'Rahmenradius',
    border_color: 'Rahmenfarbe',
    image_upload: 'Bildupload',
    alt_text: 'Alt-Text',
    width: 'Breite',
    html: 'HTML',
    stockfoto: 'Stockfoto',
    illustration: 'Digitale Illustration',
    image_with_caption: 'Bild mit Beschriftung (freier Stil)',
    stockfoto_with_text: 'Stockfoto mit Text',
    image_upload_file_input_help: 'PNG, JPG oder GIF (Bilder werden autom. optimiert).',
    image_upload_generate_image: 'Bild generieren',
    image_upload_generate_image_button: 'Bild generieren',
    image_upload_generate_image_prompt_placeholder: 'Prompt',
    schedule_email_title: 'Wann soll die E-Mail versendet werden?',
    schedule_email_time_placeholder: 'Uhrzeit auswählen',
    schedule_email_select_time_button: 'Versandzeit auswählen',
    schedule_email_schedule_button: 'E-Mail einplanen',
    schedule_email_timezone_info: 'Zeitzone: {timezone}',
    done: 'Fertig',
    new_chat: 'Neuer Chat',
    hello_name: 'Hallo {name},',
    how_can_i_help: 'Wie kann ich helfen?',
    create_editorial_plan: 'Erstelle mir einen Redaktionsplan für nächste Woche.',
    find_best_send_time: 'Hilf mir die beste Versandzeit zu finden.',
    account_audit: 'Mach einen Account-Audit.',
    ask_question: 'Frage eingeben …',
    email_variant_create_kind_title: 'Art der E-Mail',
    email_variant_create_kind_select_title: 'Bitte auswählen:',
    email_variant_kind_content: 'Content E-Mail',
    email_variant_kind_promotion: 'Aktion',
    email_variant_kind_launch: 'Produkt-Launch',
    email_variant_kind_from_website: 'E-Mail aus Webseite',
    email_variant_create_next_button: 'Weiter',
    email_variant_create_topic_title: 'Thema der E-Mail',
    email_variant_create_topic_placeholder: 'Beschreibe das Thema in einfachen Worten',
    email_variant_create_topic_example: 'Beispiel: Neue Kollektion kommt diese Woche oder Top 3 Tipps für den Sommer.',
    email_variant_create_discount_code_title: 'Beschreibe die Aktion',
    email_variant_create_discount_code_placeholder: 'Beschreibe die Aktion in einfachen Worten, auch rechtliche Aspekte und Limitierungen...',
    email_variant_create_discount_code_optional: 'Optional:',
    email_variant_create_discount_code_placeholder_discount_code: 'Trage hier den Rabatt Code ein...',
    email_variant_create_error_title: 'Leider ist ein Fehler aufgetreten!',
    email_variant_create_has_promotion_title: 'Soll die E-Mail eine Aktion oder einen Discount Code enthalten?',
    email_variant_create_has_promotion_yes: 'Ja',
    email_variant_create_has_promotion_no: 'Nein',
    email_variant_create_images_title: 'Bild Upload',
    email_variant_create_images_upload_error: 'Leider ist ein Fehler aufgetreten!',
    email_variant_create_images_upload_title: 'Bilder hochladen',
    email_variant_create_images_upload_help: 'PNG, JPG oder GIF. Bilder werden automatisch komprimiert und optimiert.',
    email_variant_create_images_upload_button: 'Bilder hochladen',
    email_variant_create_images_add_ai_images_button: 'Mit KI-Bildern ergänzen',
    email_variant_create_images_generate_ai_images_button: 'KI-Bilder generieren',
    email_variant_create_link_website_title: 'Welche Webseite soll als Vorlage dienen?',
    email_variant_create_link_promotion_title: 'Welcher Link soll beworben werden?',
    email_variant_create_start_generating_button: 'Newsletter generieren',
    email_variant_create_notes_title: 'Welche Infos sind sonst noch wichtig?',
    email_variant_create_notes_placeholder: 'Bitte füge in einfachen Worten weitere Informationen zu den relevanten Hintergründen und den zu besprechenden Themen hinzu…',
    email_variant_create_products_title: 'Wähle die Produkte:',
    email_variant_create_products_error: 'Fehler beim Laden der Produkte: {error}',
    email_variant_create_products_select_title: 'Produkt(e) auswählen:',
    email_variant_create_products_not_found: 'Nicht gefunden.',
    email_variant_create_target_title: 'Was soll beworben werden?',
    email_variant_create_target_shop_button: 'Der Shop',
    email_variant_create_target_products_button: 'Bestimmte Produkte',
    email_variant_create_target_link_button: 'Ein Link',
    email_variant_editor_placeholder_text:
      'Dieser Text ist ein Platzhalter und wird durch KI-generierten Inhalt ersetzt. <strong>Diese Worte sind besonders wichtig</strong> und wurden daher fett hervorgehoben. Manchmal benötigen wir auch <em>subtilere Betonungen</em>, die durch Kursivschrift dargestellt werden. Es ist ebenfalls nützlich, bestimmte Elemente zu <u>unterstreichen</u>, um ihre Bedeutung weiter zu unterstreichen. Für weitere Informationen, besuchen Sie bitte <a href="https://example.com">unsere Webseite</a>.',
    email_variant_editor_placeholder_button: 'Button',
    email_variant_editor_placeholder_product_title: 'Produktname',
    email_variant_editor_placeholder_product_description: 'Beschreibung',
    shop_settings_title: 'Einstellungen für {shopName}',
    shop_settings_language: 'E-Mail Sprache',
    shop_settings_custom_instructions: 'Zusätzliche Anweisungen',
    shop_settings_custom_instructions_placeholder: 'z.B. schreibe Du immer groß oder beginne jeder E-Mail mit einem Bild.',
    shop_settings_currency: 'Währung',
    shop_settings_timezone: 'Zeitzone',
    email_variant_create_error_try_again_button: 'E-Mail neu generieren',
    editor_settings_email_width: 'Breite',
    editor_settings_email_base_font_family: 'Basis Schriftart',
    editor_settings_email_background_color: 'Hintergrundfarbe',
    editor_settings_email_frame_color: 'Rahmenfarbe',
    feedback_widget_trigger_label: 'Problem melden',
    feedback_widget_trigger_aria_label: 'Problem melden',
    feedback_widget_form_title: 'Problem melden',
    feedback_widget_submit_button_label: 'Fehlerbericht senden',
    feedback_widget_cancel_button_label: 'Abbrechen',
    feedback_widget_confirm_button_label: 'Bestätigen',
    feedback_widget_add_screenshot_button_label: 'Screenshot hinzufügen',
    feedback_widget_remove_screenshot_button_label: 'Screenshot entfernen',
    feedback_widget_name_label: 'Name',
    feedback_widget_name_placeholder: 'Dein Name',
    feedback_widget_email_label: 'E-Mail',
    feedback_widget_email_placeholder: 'deine.email@example.org',
    feedback_widget_is_required_label: '(erforderlich)',
    feedback_widget_message_label: 'Beschreibung',
    feedback_widget_message_placeholder: 'Was ist das Problem?',
    feedback_widget_success_message_text: 'Vielen Dank!',
    flow_name: 'Name',
    flows_no_flows_yet: 'Hier werden deine erstellten Flows angezeigt!',
    flow_confirm_delete: 'Bist du sicher, dass du den Workflow "{name}" löschen möchtest?\n\nDiese Aktion kann nicht rückgängig gemacht werden.',
    flow_name_prompt: 'Wie soll der Flow heißen?',
  },
}

export default translations
